$(document).on('turbolinks:load', () => $('.js-select2').select2({
  language: 'zh-TW',
}));

$(document).on('change', '.js-select-bind', async (e) => {
  const { url, key, target } = $(e.currentTarget).data();
  const { value } = e.target;
  const $target = $(target);

  const { data } = await $.get(`${url}?${key}=${value}`);

  $target.addClass('js-select-binded').html('<option value="">全部</option>');

  data.forEach((obj) => {
    $target.append(`<option value="${obj.id}">${obj.label}</option>`);
  });

  if ($target.data('target')) {
    $($target.data('target')).html('');
  }

  $target.trigger('change.select2');
});
