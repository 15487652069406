/* eslint no-console:0 */
/* global Turbolinks */
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '@babel/polyfill';
import 'normalize.css';
import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';
import '../stylesheets/application.scss';
import '../initializers/embed-map';
import '../initializers/select2';
import '../initializers/carousel';

import BillboardCarousel from '../billboard-carousel';
import bindDeepLink from '../deep-link';

$(document).on('turbolinks:load', () => {
  BillboardCarousel({ selector: '#billboard-carousel' });
  bindDeepLink('.pagination__button');

  $('[data-mfp-src]').magnificPopup({
    type: 'inline',
  });
});

// https://github.com/turbolinks/turbolinks/issues/272
// support remote get forms ... since turbolinks fails to do this properly
$(document).on('submit', 'form[method=get][data-remote=true]', e => {
  e.preventDefault();

  const $form = $(e.currentTarget);

  Turbolinks.visit(`${$form.attr('action')}?${$form.serialize()}`);
});

$(document).on('click', '#billboard-search-advanced-trigger', () => {
  $('#billboard-search-advanced').prop('disabled', (index, value) => !value);
  $('#billboard-search').toggleClass('billboard-search--advanced');
});

$(document).on('reset', '#billboard-search', e => {
  e.preventDefault();

  const $this = $(e.currentTarget);

  $('input:not(:submit)', $this).val(null);
  $('.js-select-binded', $this).html('');
  $('select', $this)
    .val(null)
    .trigger('change.select2');
});
