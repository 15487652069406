const context = require.context('./media/images/billboard-banners', false, /\.jpg$/);
const banners = context.keys().map(context);
const slideClassName = 'billboard__carousel-cell';

let current = 0;
let $container;

function preload(images, index = 0) {
  if (!images || images.length <= index) return;

  const image = new Image();

  image.onload = () => preload(images, index + 1);
  image.src = images[index];
}

function updateSlides() {
  const next = current + 1;
  const $front = $container.children().eq(1);

  $front
    .one('webkitTransitionEnd otransitionend msTransitionEnd transitionend', () => {
      $front.detach();
      $front.css('background-image', `url(${banners[next]})`);
      $front.prependTo($container);
      $front.removeClass('fade-out');
    })
    .addClass('fade-out');
}

function tick() {
  if (current === banners.length - 2) {
    current = -1;
    updateSlides();
    return;
  }

  current += 1;
  updateSlides();
}

export default ({ selector, timeout = 4000 }) => {
  $container = $(selector);

  if ($container.length <= 0) return;

  preload(banners);

  $container.html(`
    <div class=${slideClassName}></div>
    <div class=${slideClassName}></div>
  `);

  const next = current + 1;

  const $slides = $container.children();
  $slides.eq(0).css('background-image', `url(${banners[next]})`);

  if ($container.data('autoplay') === false) return;

  $slides.eq(1).css('background-image', `url(${banners[current]})`);

  const autoplay = setInterval(tick, timeout);

  $(document).on('turbolinks:before-render', () => {
    clearInterval(autoplay);
  });
};
