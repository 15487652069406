/* global google */
import MarkerClusterer from '@google/markerclusterer';

const getGoogleMapApi = () =>
  $.ajax({
    dataType: 'script',
    cache: true,
    url: `https://maps.googleapis.com/maps/api/js?key=${
      process.env.GOOGLE_MAP_API_KEY
    }`,
  });

function renderInfoContent({ total_price, link, label, owners }) {
  var text = [];
  const open = `
    <div class="info-window">
      <div>${label}</div>`;
  const close = `
      <div><a href=${link}>詳細資料</a></div>
    </div>
  `;
  text.push(open);
  if (owners) {
    owners.map(owner => {
      text.push(`<div>${owner}</div>`);
    })
  }
  if (total_price) {
    text.push(`<div>總價：${total_price}</div>`);
  }
  text.push(close);
  return text.join('');
}

const initMap = () => {
  $('.embed-map-container').each((index, embedMap) => {

    // 套真資料需要 uncomment
    const { map: mapData } = embedMap.dataset;
    if (!mapData) return;

    // 套真實資料需要 uncomment 或視 ruby 提供的 data 格式選擇刪除這行（刪除的話再下一行的 data 要改成 mapData）
    const data = JSON.parse(mapData);
    const { lat, lng, markers, range, zoom } = data;
    const center = { lat, lng };

    const map = new google.maps.Map(embedMap, {
      center,
      zoom: zoom,
    });

    map.data.setStyle({
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
    });

    if (range && range.length > 0)
      map.data.add({ geometry: new google.maps.Data.Polygon([range]) });

    if (!markers) {
      const { label } = data;
      const marker = new google.maps.Marker({
        map,
        position: center,
        title: label,
      });
      return;
    }

    const mapMarkers = markers.map(markerData => {
      const { lat, lng, label, id } = markerData;
      const marker = new google.maps.Marker({
        map,
        position: { lat, lng },
        title: label,
      });
      const infoWindow = new google.maps.InfoWindow({
        content: renderInfoContent(markerData),
      });
      marker.addListener('click', () => {
        infoWindow.open(map, marker)
        $.post('/api/counters/map_click', { asset_id: id })
      });
      return marker;
    });

    const markerCluster = new MarkerClusterer(map, mapMarkers, {
      // TODO: MarkerClusterer 會去抓 m1.png, m2.png...m5.png 這樣路徑的圖檔，這邊只能指定到 `m` 為止的路徑, 如果後面還有 hash 會抓不到
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    });
  });
};

$(document).on('turbolinks:load', async () => {
  const $html = $('html');

  if ($html.hasClass('local-production')) return;

  if (!$html.hasClass('maps-api-loaded')) {
    await getGoogleMapApi();
    $html.addClass('maps-api-loaded');
  }

  initMap();
});
