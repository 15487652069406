export default function deepLink(selector) {
  $(document).on('click', selector, (event) => {
    event.preventDefault();

    const href = $(event.currentTarget)
      .find('a')
      .attr('href');

    window.location.href = href;
  });
}
